import { endOfMonth, getDate } from "date-fns"
import { useEffect, useState } from "react"
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { Input } from "reactstrap"
import CustomCard from "../../components/customCard"
import Header from "../../components/header"
import { BackButton, ContainerBtnNextBtnPrev, NextButton } from "../../components/renderField/styles"
import { useAuth } from "../../context/AuthContext"
import { useToast } from "../../context/Toast"
import countries from '../../data/countries.json'
import { getCompanyId } from "../../services/company/company"
import { getUserByEmail, updateUser } from "../../services/user/user"
import { StyledSelect, StyledSelectEmployee } from "../settings/modalUser/styles"
import { StyledText } from "../settings/styles"
import { ContainerInputs, LabelCheckbox, LabelInput, LabelNames, StyledCardTitle, StyledLink, StyledOptions, StyledTextEmployee } from "./style"
import { CardEmployee } from "../welcome/styles"
interface IFormState {
  first_name: string
  last_name: string
  email: string
  birth_date: string
  country: string
  /*ethnicity: string;*/
  newsletter_sub: string
  sex: string
  id: string
  company: string
  group: any[]
  status?: number
}

const UserRegister: React.FC = () => {
  const navigate = useNavigate()
  const { showToast } = useToast()
  const { saveDataSignUpOnStorage, user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [controlLoading, setControlLoading] = useState(false)
  const [form, setForm] = useState<IFormState>({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    birth_date: '',
    country: '',
    /*ethnicity: '',*/
    newsletter_sub: 'N',
    sex: '',
    company: '',
    group: []
  })

  const handleInputChange = (event: any) => {
    const { name, type, checked, value } = event.target
   
    // if (name === 'birth_date') {
    //   const selectedDate = new Date(value);

    //   // Verifica se o ano é maior que o ano atual
    //   const currentYear = new Date().getFullYear();
    //   const selectedYear = selectedDate.getFullYear();
    //   if (selectedYear > currentYear) {
    //       // Se o ano for maior que o ano atual, ajusta o valor para o ano atual
    //       event.target.value = `${currentYear}-${('0' + (selectedDate.getMonth() + 1)).slice(-2)}-${('0' + selectedDate.getDate()).slice(-2)}`;
    //   }
    // }

    if (type === "checkbox") {
      setForm({
        ...form,
        [name]: checked ? 'S' : 'N'
      })
    } else {
      setForm({
        ...form,
        [name]: event.target.value
      })
    }
  }

  useEffect(() => {
    const loadData = async (dataUser: any) => {
      try {

        setLoading(true)

        let result = await getUserByEmail(dataUser.user.email)

        if (result) {
          setForm(prevForm => ({
            ...prevForm,
            id: result.id,
            first_name: result.first_name || '',
            last_name: result.last_name || '',
            email: result.email || '',
            birth_date: result.birth_date || '',
            country: result.country,
            // ethnicity: result.ethnicity || '',
            sex: result.sex || '',
            role: result.role || '',
            company: result.company?.Item?.id || '',
          }))


          const dataToSave = {
            id: result.id,
            email: result.email,
            first_name: result.first_name,
            last_name: result.last_name,
            status: result?.status,
            ethnicity: "0",
            company_id: result.company?.Item?.id,
            company_name: result.company?.Item?.name_company,
            role: result?.role
          }
          saveDataSignUpOnStorage(dataToSave)

        }


      } catch (e: any) {
        setLoading(false)

        throw e

      }
      setLoading(false)

    }
    const authUser = localStorage.getItem('zenbox.identity')
    if (authUser) loadData(JSON.parse(authUser))

  }, [])


  const onSubmit = async () => {
    setLoading(true)
    let formData = { ...form, email: user.user.email }
    const { birth_date, country, first_name, /*ethnicity,*/ last_name, email, newsletter_sub, sex } = formData
    if (!birth_date || !country || !first_name || /*!ethnicity ||*/ !last_name || !email || !sex || newsletter_sub == 'N') {
      setLoading(false)
      return showToast('Preencha todos os campos', 'error')
    }
    try {
      setControlLoading(true)

      if (user.verifyToken == process.env.REACT_APP_AUTO_SIGNUP_TOKEN) {
        formData.status = 2;
      }
    
      if(user.user.status === 2){
        formData.status = 1 
      } else if(user.user.status === 4){
        formData.status = 3
      }

        
      await updateUser(formData)
      try {
        const userDb = await getUserByEmail(user.user?.email)
        const dataToSave = {
          id: userDb.id,
          email: userDb.email,
          first_name: userDb.first_name,
          last_name: userDb.last_name,
          status: (userDb.status === 2) ? 1 : userDb.status,
          ethnicity: "0",
          company_id: userDb.company?.Item?.id,
          company_name: userDb.company?.Item?.name_company,
          role: userDb?.role
        }
        saveDataSignUpOnStorage(dataToSave)
      } catch (error) {
        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para mais informações, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 20000)
        throw error
      }
      try {
        const company = await getCompanyId(user.user.company_id)
        showToast('Cadastro finalizado com sucesso!', 'success', 8000)
        if (!company.Item.surveys_all_month) {
          const today = new Date()
          const day = getDate(today)
          const lastDayOfMonth = getDate(endOfMonth(today))
          if (!(day >= 20 && day <= lastDayOfMonth)) {
            showToast('Seu cadastro foi realizado\n\nPorém só é possível responder a reflexão do mês durante o período do dia 20 até o último dia do mês\n\nVolte no próximo período para enviar sua reflexão.', 'info', 30000)
            setLoading(false)
            navigate('/welcome')
          
          } else {
            setLoading(false)
            if (user.user.status !== 1 && user.user.status !== 2) {
              showToast('Sua conta está inativada.\n\nPara reativá-la peça ao Gestor de Felicidade da sua organização ou fale com nosso suporte pelo botão de ajuda no canto inferior direito da tela.', 'info', 18000)
              navigate('/welcome')
              return;
            }
            navigate('/questions')
            showToast('Responda sua reflexão do mês :)\n\nAproveite enquanto ainda estamos no período de reflexão. Todo mês você poderá enviar uma ou mais vezes sua reflexão durante o período do dia 20 até o último dia do mês.', 'info', 30000)
          }
        } else {
          setLoading(false)
          if (user.user.status !== 1 && user.user.status !== 2) {
            showToast('Sua conta está inativada.\n\nPara reativá-la peça ao Gestor de Felicidade da sua organização ou fale com nosso suporte pelo botão de ajuda no canto inferior direito da tela.', 'info', 18000)
            navigate('/welcome')
            return;
          }
          navigate('/questions')
          showToast('Responda sua reflexão do mês :)', 'info', 8000)
        }

      } catch (error) {
        setLoading(false)
        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para mais informações, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 20000)
        throw error
      }
      setControlLoading(false)
      setLoading(false)
    } catch (error) {
      setControlLoading(false)
      setLoading(false)
      showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para mais informações, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 20000)
      throw error
    }
    setLoading(false)
  }

  return (

    <>
      <Header />

      <Container fluid className="d-flex h-100 mt-5 mb-2">
        <Row className="justify-content-center align-self-center w-100">
          {loading ? (


            <div className="d-flex align-items-center justify-content-center" style={{
              minHeight: '300px',

            }}>
              <Spinner style={{ color: '#c9c9c9' }}></Spinner>

            </div>
          ) : (
            form.id ? (
              <Col md={9} lg={7}>
                <CardEmployee>
                  <Card.Header className="py-3">
                    <StyledCardTitle>{'Termine seu cadastro'}</StyledCardTitle>
                  </Card.Header>

                  <ContainerInputs style={{ minHeight: '220px', paddingBottom: '15px' }}>
                    <Row>
                      <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="first_name">
                          <LabelNames style={{ color: ' #FFF' }}>Primeiro Nome</LabelNames>
                          <StyledTextEmployee style={{ paddingRight: 10 }}
                            type="text"
                            name="first_name"
                            value={form?.first_name}
                            onChange={handleInputChange}
                            aria-label="Primeiro Nome"
                            aria-required="true"
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="last_name">
                          <LabelNames style={{ color: ' #FFF' }}>Último Nome</LabelNames>
                          <StyledTextEmployee style={{ paddingRight: 10 }}
                            type="text"
                            name="last_name"

                            value={form?.last_name}
                            onChange={handleInputChange}
                            aria-label="Último Nome"
                            aria-required="true"
                          />
                        </Form.Group>
                      </Col>

                    </Row>

                    <Row className='mt-2 '>
                      <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="country">
                          <LabelNames style={{ color: ' #FFF' }}>País</LabelNames>
                          <StyledTextEmployee style={{ }}
                            type="select"
                            name="country"
                            value={form?.country || ''}
                            onChange={handleInputChange}

                          >
                            <option value='' disabled hidden>Clique para selecionar</option>
                            {countries.map((country) => (
                              <option key={country.nome_pais} value={country.nome_pais}>
                                {country.nome_pais}
                              </option>
                            ))}
                          </StyledTextEmployee>
                        </Form.Group>
                      </Col>

                      <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="birthday">
                          <LabelNames style={{ color: ' #FFF', textAlign: "left", }}>Data de Nascimento</LabelNames>
                          <StyledTextEmployee style={{ paddingRight: 10 }}
                            type='date'
                            name='birth_date'
                            value={form?.birth_date}
                            onChange={handleInputChange}
                            max='9999-12-31'
                            min='1900-01-01'
                          />
                        </Form.Group>
                      </Col>


                    </Row>
                    <Row className='mt-2 '>
                      <Col xs={12} sm={6}>
                        <Form.Group className="mb-3" controlId="sex">
                          <LabelNames style={{ color: ' #FFF' }}>Gênero</LabelNames>
                          <StyledSelectEmployee style={{ height: '46px' }}
                            size='sm'
                            type='select'
                            name='sex'
                            value={form?.sex}
                            onChange={handleInputChange}>

                            <StyledOptions value='' disabled defaultValue='' hidden>Clique para selecionar</StyledOptions>
                            <StyledOptions value={'1'}>Masculino</StyledOptions>
                            <StyledOptions value={'2'}>Feminino</StyledOptions>
                            <StyledOptions value={'3'}>Outros</StyledOptions>
                          </StyledSelectEmployee>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6}>
                        {/* <Form.Group className="mb-3" controlId="ethnicity"> */}
                        {/* CAMPO DESABILITADO POR ENQUANTO */}

                        {/* <LabelNames style={{ color: 'white', width: '100%', }}>Etnia</LabelNames>
                          <StyledSelect
                          style={{ background: '#2A2A48', height: '46px' }}
                            size='sm'
                            name='ethnicity'
                            value={form?.ethnicity}
                            onChange={handleInputChange}
                          defaultValue=''  
                        >
                            <option value='' disabled defaultValue = '' hidden>Clique para selecionar</option>
                            <option value={'1'}>Branco</option>
                            <option value={'2'}>Negro</option>
                            <option value={'3'}>Asiático</option>
                            <option value={'4'}>Indígena</option>
                            <option value={'5'}>Multirracial/Mestiço</option>
                          </StyledSelect> */}

                        {/* CAMPO DESABILITADO POR ENQUANTO */}
                        {/* </Form.Group> */}
                      </Col>
                      <Col xs='auto'>
                        <Form.Group className="mb-3" >

                          <LabelInput>
                            <Input
                              style={{ border: ' 1px solid rgba(60, 64, 67, 0.30)'  , padding: '.5em', fontSize: '', verticalAlign: 'middle' }}
                              id="newsletter"
                              type="checkbox"
                              name='newsletter_sub'
                              onChange={handleInputChange}
                              required
                            />
                            <LabelCheckbox style={{ color: ' #FFF' }}>Aceito os <StyledLink target="_blank" href="https://zenbox.life/termos-de-uso">Termos de Uso</StyledLink>  e <StyledLink target="_blank" href="https://zenbox.life/politicas-de-privacidade">Políticas de Privacidade</StyledLink> sobre meus dados</LabelCheckbox>
                          </LabelInput>
                        </Form.Group>
                      </Col>
                    </Row>
                  </ContainerInputs>
                  <Card.Footer>
                    <ContainerBtnNextBtnPrev>

                      <BackButton disabled={controlLoading} onClick={() => { navigate('/welcome') }} >Voltar</BackButton>
                      <NextButton disabled={controlLoading} onClick={onSubmit}> {loading ? "Enviando..." : "Enviar"}</NextButton>
                    </ContainerBtnNextBtnPrev>
                  </Card.Footer>
                </CardEmployee>
              </Col>
            ) : (
              <>
                <Col md={9} lg={7}>
                  <CardEmployee>
                    <Card.Body className="py-3">
                      <StyledCardTitle>{'Usuário não encontrado'}</StyledCardTitle>
                    </Card.Body>
                  </CardEmployee>
                </Col>
              </>
            )
          )}


        </Row>
      </Container>


    </>








  )
}

export default UserRegister
